import React from "react"
import { Layout, Parallax, NewsletterSection } from "@components/layout"
import { Breadcrumbs } from "@components/shared"

import { PostItem, BlogHero } from "@components/blog"

import { posts__wrapp } from "./styles/categories.module.scss"

const Posts = ({ posts }) => {
  return (
    <React.Fragment>
      <div className={posts__wrapp}>
        {posts.map((el, k) => (
          <PostItem {...el} key={k} />
        ))}
      </div>
    </React.Fragment>
  )
}

const SingleCategory = ({ pageContext }) => {
  const { posts, page, categories } = pageContext
  const { translations } = page
  const translationUrls = {
    pl:`/pl/blog/categories/${translations[0].slug}/`,
    en:`/en/blog/categories/${translations[0].slug}/`
  }
  let selectedLanguage = "/pl"
  if(window !== "undefined"){
    selectedLanguage = localStorage.getItem("language")
  }
  return (
    <Layout seo={page.seo} siteMetaData={page.siteMetaData} translationUrl={translationUrls}>
      <BlogHero title={page.name} />
      <Breadcrumbs
        elements={[
          {
            label: "Blog",
            url: `${selectedLanguage}/blog/`,
          },
          {
            label: page.name,
            url: `${selectedLanguage}/blog/categories/${page.slug}/`,
          },
        ]}
      />
      <Posts posts={posts} />
      <NewsletterSection />
    </Layout>
  )
}

export default SingleCategory
